
import React, { useState, useEffect, useContext } from 'react';
import service from '../../../api/services';
import Wrapper from '../../../components/common/Wrapper/Wrapper';
import organisms from '../../../components/organisms';
import { Default_Page_Limit } from "../../../constants/defaultValues"
import { getDropdown, getParams } from '../../../constants/utils';
import { HeaderHeading } from '../../../Context';
import { useSearchParams } from 'react-router-dom';
import constant from '../../../constants/constant';
import { constants } from 'buffer';
// import { HeaderHeading } from '../../../Context';


const { Inventory } = organisms

const Index = () => {
  const [searchParams] = useSearchParams();
  const [typeValue, setTypeValue] = useState<any>(getParams('inventory-search-type') == constant.BRAND_CATEGORY[0]?.value)
  const [brandData, setBrandData] = useState<any>();
  const [limit, setLimit] = useState(Default_Page_Limit);
  const [listingData, setListingData] = useState<any>()
  const [isOpen, setIsOpen] = useState(false);
  const [noDataText, setNoDataText] = useState<Boolean>(false)
  const { setHeader }: any = useContext(HeaderHeading);
  const [isContactLensProduct,setIsContactLensProduct]= useState<boolean>(false)
  const [searchdata, setSearchdata] = useState<any>({
    brand: [],
    store: [],
    product_id: "",
    modal_number: "",
    barcode: "",
    minPrice: "",
    maxPrice: ""
  })

  useEffect(() => {
    setTypeValue(getParams('inventory-search-type') == constant.BRAND_CATEGORY[0]?.value)
    setIsContactLensProduct(false)
    setSearchdata({
      brand: [],
      store: [],
      product_id: "",
      modal_number: "",
      barcode: "",
      price: '',
      created_from:"",
      created_to:"",
    })
  }, [searchParams])
  const handleSubmit = async () => {
    const brandIds = searchdata?.brand?.map((item: any) => item.value)
    const payload = {
      brand: brandIds,
      product_id: searchdata.product_id.replace(/ +/g, ""),
      modal_number: searchdata.modal_number.replace(/ +/g, ""),
      barcode: searchdata.barcode,
      is_contact_lens: isContactLensProduct,
      price: {
        "minPrice": searchdata.minPrice,
        "maxPrice": searchdata.maxPrice
      },
      is_recognised: typeValue 
    }
    try {
      const response = await service.inventory.searchView(payload)
      if (response.status == 200) {
        setListingData(response.data.data)
        setNoDataText(true)
        if (response?.data?.data.length > 0) {
          setIsOpen(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getInvoiceData = async () => {
    try {
      // let storeResponse = await service.inventory.storeView()
      let brandResponse = await service.inventory.brandView({ is_recognised: typeValue })

      if (brandResponse.status === 200) {
        // setStoreData(getDropdown(storeResponse?.data?.data, 'store_name'))
        setBrandData(getDropdown(brandResponse?.data?.data, 'brand_name'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLensTypeChange = (value:boolean)=>{
    setIsContactLensProduct(value)
    setSearchdata({
      brand: [],
      store: [],
      product_id: "",
      modal_number: "",
      barcode: "",
      price: '',
      created_from:"",
      created_to:"",
    })
  }

  useEffect(() => {
    setHeader("Inventory")
    getInvoiceData()
    handleSubmit()
  }, [typeValue,isContactLensProduct])


  return (
    <Wrapper>
      <Inventory
        brandData={brandData}
        searchdata={searchdata}
        setSearchdata={setSearchdata}
        handleSubmit={handleSubmit}
        listingData={listingData}
        setLimitPerPage={setLimit}
        limit={limit}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noDataText={noDataText}
        isRecognized={typeValue == constant.BRAND_CATEGORY[0]?.value}
        isContactLensProduct={isContactLensProduct}
        handleLensTypeChange={(value:boolean)=>handleLensTypeChange(value)}
      />
    </Wrapper>
  )
}
export default Index