import PlaceHolderImage from '../../../../assets/Images/placeholderimg.png'
import atoms from "../../../atoms";
import moment from 'moment'
import { convertJsonToExcel, numberWithCommas } from "../../../../constants/utils"
import { MyComponentProps, ApiResponse } from "./interface"
import molecules from '../../../molecules';

const { Pagination, Search, Table, Input, Button } = atoms
const {ContactLensContainer} = molecules

const Index = ({ transferRequestMoveOutList, setSearch, currentPage, setCurrentPage,
    setLimitPerPage, limit, setCreatedFrom, setCreatedTo,isContactLensProduct,setIsContactLensProduct }: MyComponentProps) => {

    const columns = [
        { name: 'Product ID', selector: (row: ApiResponse) => row?.procurement_item?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: ApiResponse) => row?.procurement_item?.product?.brand?.brand_name || '-', },
        {
            name: 'Cover Photo',
            cell: (row: ApiResponse) => {
                const style = {
                    height: "75%",
                    width: "100%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${row?.procurement_item?.product?.images ? row?.procurement_item?.product?.images[0] : PlaceHolderImage})`
                }
                return <div style={style}></div>;
            },
        },
        { name: 'SRP', selector: (row: ApiResponse) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: ApiResponse) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Model No', selector: (row: ApiResponse) => row?.procurement_item?.product?.modal_number, },
        { name: 'Color Code', selector: (row: ApiResponse) => row?.procurement_item?.product?.brand_color_code || '-', },
        { name: 'With Case', selector: (row: ApiResponse) => row?.procurement_item?.product?.product_with_case ? "Yes" : "No", },
        { name: 'Date', selector: (row: ApiResponse) => moment(row?.createdAt).format('DD/MM/YYYY,  H:mm:ss') || '-', },
        { name: 'Pickup Note No.', selector: (row: ApiResponse) => row?.procurement_item?.pickup_note_num || '-', },
        { name: 'Delivered to', selector: (row: any) => row?.destination_store?.store_location || row?.destination_store?.store_name,minWidth:"150px" },
    ];

    const columnsForContactLens = [
        { name: 'Product ID', selector: (row: ApiResponse) => row?.procurement_item?.product?.product_id_display, minWidth: '10vw' },
        { name: 'Brand', selector: (row: ApiResponse) => row?.procurement_item?.product?.brand?.brand_name || '-', },
        {
            name: 'Cover Photo',
            cell: (row: ApiResponse) => {
                const style = {
                    height: "75%",
                    width: "100%",
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url(${row?.procurement_item?.product?.images ? row?.procurement_item?.product?.images[0] : PlaceHolderImage})`
                }
                return <div style={style}></div>;
            },
        },
        { name: 'SRP', selector: (row: ApiResponse) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.product?.product_srp)?.toFixed(2)))}` || '-', },
        { name: 'Landing Cost', selector: (row: ApiResponse) =>`₹${numberWithCommas(parseFloat(Number(row?.procurement_item?.landing_cost)?.toFixed(2)))}` || '-', },
        { name: 'Date', selector: (row: ApiResponse) => moment(row?.createdAt).format('DD/MM/YYYY,  H:mm:ss') || '-', },
        { name: 'Pickup Note No.', selector: (row: ApiResponse) => row?.procurement_item?.pickup_note_num || '-', },
        { name: 'Delivered to', selector: (row: any) => row?.destination_store?.store_location || row?.destination_store?.store_name,minWidth:"150px"},
    ]

    const handleDownloadExcel = () => {
        if (transferRequestMoveOutList?.data?.length > 0) {
            const excelData = transferRequestMoveOutList?.data?.map((item: any) => {
                const rowData: any = {};
                columns.forEach((column: any) => {
                    if (column.name != "Cover Photo") {
                        rowData[column.name] = column?.selector(item);
                    }
                });
                return rowData;
            });

            convertJsonToExcel(excelData, "Inventory_excel")
        }
    }


    return (
        <>
            {/* <div className="row align-items-center">
                <div className="col-12 col-lg-4 mb-lg-0 mb-2">
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100"/>
                </div>
                <div className="col-12 col-lg-8">
                    <div className="d-lg-flex gap-4 align-items-center justify-content-end">
                        <div className="d-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label mb-2 fs-16 fw-600 my-auto secondary-color'>From</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter End Date"}
                                onChange={(e: any) => {
                                    setCreatedFrom(e.target.value)
                                }} />
                        </div>
                        <div className="d-flex mb-lg-0 mb-2 align-items-center gap-lg-2 gap-4">
                            <label className='inputs-label mb-2 fs-16 fw-600 my-auto secondary-color'>To</label>
                            <Input
                                type={'Date'}
                                className="input-height"
                                placeholder={"Enter Start Date"}
                                onChange={(e: any) => {
                                    setCreatedTo(e.target.value)
                                }} />
                        </div>
                        <div className="">
                            <Button>Download</Button>
                        </div>
                    </div>
                </div>
            </div> */}
             <div className="d-flex mb-3">
                <ContactLensContainer
                    value={isContactLensProduct}
                    onStatusChange={(value: boolean) => setIsContactLensProduct(value)}
                />
                </div>
            <div className='row'>
                <div className='col-12 col-md-4 mb-lg-0 mb-3'>
                    <Search searchCallBack={(e: string) => { setSearch(e) }} className="w-100" />
                </div>
                <div className='col-12 col-md-8 gap-2 d-lg-flex justify-content-lg-end px-lg-0'>
                    <div className='row ps-lg-3 justify-content-end'>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>From</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter End Date"}
                                    onChange={(e: any) => {
                                        setCreatedFrom(e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="col-6 d-md-flex mb-lg-0 mb-2 align-items-center gap-2">
                            <label className='inputs-label fs-16 fw-600 secondary-color'>To</label>
                            <div className='w-100'>
                                <Input
                                    type={'Date'}
                                    className="input-height change-min-height"
                                    placeholder={"Enter Start Date"}
                                    onChange={(e: any) => {
                                        setCreatedTo(e.target.value)
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-lg-center justify-content-end'>
                        <Button onClick={() => handleDownloadExcel()}>Download</Button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    columns={isContactLensProduct ? columnsForContactLens : columns}
                    AdditionalClassName="table-height-50"
                    data={transferRequestMoveOutList?.data?.map((item: any) => {
                        return ({
                            ...item
                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={transferRequestMoveOutList?.count}
                    currentPage={currentPage}
                    totalPages={transferRequestMoveOutList?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limit}
                />
            </div>
        </>
    )
}

export default Index